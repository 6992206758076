import axios from 'axios'
import { headersFormData, objectToFormData } from '@/utils/form-data'

export const actions = {
  async fetch(store, params) {
    const res = await axios.get('api/sales/admin/settings/popup/skip-order', { params })
    return res.data
  },
  async update(store, params) {
    const formData = new FormData()
    formData.append('_method', 'PUT')

    await axios.post(`api/sales/admin/settings/popup/skip-order`, objectToFormData(params, formData), { ...headersFormData })
  },
}
