<template>
  <div>
    <div class="flex items-center justify-between bg-gray-800 px-4 py-4 sm:bg-gray-200 w-full">
      <h3 class="text-2xl font-semibold text-gray-100 sm:text-gray-900">App Settings</h3>
    </div>
    <div class="mt-8">
      <div v-if="loaded">
        <div class="overflow-hidden bg-white shadow sm:rounded-md mb-10 ">
            <ul class="divide-y divide-gray-200" v-for="(settingGroup, title) in settingsByGroup" :key="title">
              <router-link
                  :to="{ name: 'edit-setting', params: { group: title } }"
                  class="flex items-center justify-between px-4 py-2 text-gray-700 hover:text-teal-500 flex-grow"
                >
                <h1 class="p-4 capitalize text-xl">{{titleHuman(title)}}</h1>
                <div class="ml-auto">
                  <svg
                    class="h-6 w-6 stroke-current lg:h-8 lg:w-8"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <polyline points="9 18 15 12 9 6"></polyline>
                  </svg>
                </div>
              </router-link>
            </ul>
          </div>

      </div>
      <loader v-else />
    </div>

    <div class="flex items-center justify-between bg-gray-800 px-4 py-4 sm:bg-gray-200 w-full">
      <h3 class="text-2xl font-semibold text-gray-100 sm:text-gray-900">Popup Settings</h3>
    </div>
    <div class="mt-8">
      <div v-if="loaded">
        <div class="overflow-hidden bg-white shadow sm:rounded-md mb-10 ">
          <ul class="divide-y divide-gray-200" >
            <li>
              <router-link
                :to="{ name: 'edit-custom-skip-order-deflect-popup'}"
                class="flex items-center justify-between px-4 py-2 text-gray-700 hover:text-teal-500 flex-grow"
              >
                <h1 class="p-4 capitalize text-xl">Custom skip order deflect popup</h1>
                <div class="ml-auto">
                  <svg
                    class="h-6 w-6 stroke-current lg:h-8 lg:w-8"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <polyline points="9 18 15 12 9 6"></polyline>
                  </svg>
                </div>
              </router-link>
            </li>
          </ul>
        </div>

      </div>
      <loader v-else />
    </div>
</div>
</template>
<script>

import { mapActions, mapGetters } from 'vuex'
import {groupBy} from 'lodash'

export default {
  data: () => ({
    settingsByGroup: [],
    loaded: false,
  }),
  async mounted() {
    try {
      await this.fetchItems()
      this.loaded = false
      this.settingsByGroup = groupBy(this.settingsFromStore, 'group')
      this.loaded = true
    } catch(err) {
      console.log(err)
      this.errorMessage(err?.response?.data?.message || err)
    }
  },
  computed: {
    ...mapGetters({
      settingsFromStore: 'settings/all'
    })
  },
  methods: {
    ...mapActions({
      fetchItems: 'settings/fetch',
    }),
    titleHuman(title) {
      return title.replace(/_/g, ' ')
    }
  }
}
</script>
