<template>
  <div class="mt-10 overflow-hidden rounded-lg bg-white p-4 shadow">
    <h4 class="mb-5 font-bold">Experiments:</h4>
    <form class="mt-4 w-full" @submit.prevent="save">
      <div v-for="experiment in experiments" :key="experiment.id" class="form-group select">
        <label class="">
          {{ experiment.name }}
          <select v-model="userExperiments[experiment.name]">
            <option v-for="variant in experiment.variants.data" :key="variant.code" :value="variant.code">
              {{ variant.code }}
            </option>
          </select>
        </label>
      </div>

      <button class="btn btn-green"
              type="submit"
      >
        Save
      </button>
    </form>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import FormMixin from '~/mixins/FormMixin'
import _ from 'lodash'

export default {
  props: ['user'],
  mixins: [FormMixin],
  data() {
    return {
      userExperiments: {}
    }
  },
  async mounted() {
    const userExperiments = (await this['experiments/fetchByUserId'](this.user.id)).data
    await this['experiments/fetch']()

    _.each(userExperiments, function(item) {
      this.$set(this.userExperiments, item.experiment_name, item.code)
    }.bind(this))
  },
  computed: {
    ...mapGetters([
      'experiments/all'
    ]),
    experiments() {
      return this['experiments/all']
    }
  },
  methods: {
    ...mapActions(['experiments/fetchByUserId', 'experiments/updateExperimentVariantsForUserId',
      'experiments/fetch']),

    save() {
      const payload = {
        user_id: this.user.id,
        experiments: this.userExperiments
      }
      this.wrapSubmit(this['experiments/updateExperimentVariantsForUserId'](payload))
        .then(() => {
          this.successMessage('User experiments updated!')
        })
        .catch(() => {
          this.errorMessage('Error Occurred!')
        })
    }
  }
}
</script>
